<template>
    <section
    class="pt-3 position-relative custom-scroll overflow-auto"
    :class="$route.name == 'admin.cedis.estadisticas' ? ' height-cedis' : 'height-otro'"
    >
        <timercomponent v-if="cargandoTerminado" v-model="tipo_fecha" @refresh="montar()" />
        <template v-if="$route.name == 'admin.cedis.estadisticas'">
            <div class="row mx-0 mt-1 mb-4">
                <div class="col-12 pl-4 bg-white d-middle br-12 py-2 mx-1">
                    <div class="bg-general3 d-middle-center rounded-circle cr-pointer" style="width:20px;height:20px;" @click="$router.push({name:'admin.cedis'})">
                        <i class="icon-left text-white f-14" />
                    </div>
                    <p class="mx-1 f-18 f-400 text-gr-general"> {{ info_cedis.nombre }} </p>
                </div>
                <div class="col-10 br-10 ml-4 bg-light-f5">
                    <div class="row justify-content-start mx-0">
                        <div class="col-auto">
                            <p>
                                <i class="icon-package-variant-closed text-general2 f-22 " />
                                <span class="f-600 text-general"> {{ agregarSeparadoresNumero(info_cedis.valores.productos) }} Productos </span>
                            </p>
                        </div>
                        <div class="col-auto">
                            <p>
                                <i class="icon-format-list-checks text-general2 f-22 " />
                                <span class="f-600 text-general"> {{ agregarSeparadoresNumero(info_cedis.valores.unidades) }} Unidades </span>
                            </p>
                        </div>
                        <div class="col-auto">
                            <p>
                                <i class="icon-cash text-general2 f-22 " />
                                <span class="f-600 text-general"> {{ separadorNumero(info_cedis.valores.valores) }} </span>
                            </p>
                        </div>
                        <div class="col-auto f-600 text-general">
                            <img :src="info_cedis.responsable.imagen" class="obj-cover rounded-circle border" width="35" height="35" />
                            Responsable: {{ info_cedis.responsable.nombre }}
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="row mx-0">
                <div class="col-4 px-0 bg-white d-flex align-items-start br-12 py-2 mx-5">
                    <p class="mx-1 f-18 f-500 text-general">Estado de inventario de todos los cedis:</p>
                </div>
                <div class="col py-2">
                    <div class="row justify-content-start mx-0">
                        <div class="col-auto">
                            <p>
                                <i class="icon-package-variant-closed text-general2 f-22 " />
                                <span class="f-600 text-general"> {{ agregarSeparadoresNumero(cedis_valores.productos) }} Productos </span>
                            </p>
                        </div>
                        <div class="col-auto">
                            <p>
                                <i class="icon-format-list-checks text-general2 f-22 " />
                                <span class="f-600 text-general"> {{ agregarSeparadoresNumero(cedis_valores.unidades) }} Unidades </span>
                            </p>
                        </div>
                        <div class="col-auto">
                            <p>
                                <i class="icon-cash text-general2 f-22 " />
                                <span class="f-600 text-general"> {{ separadorNumero(cedis_valores.valores) }}  </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template>
            <div class="row f-18 f-600 px-5 mx-0 my-2 text-general mt-5 mb-3">
                Resultados comparativos
            </div>
            <div class="row mx-0 px-4 mb-5 position-relative">
                <!-- Filtro -->
                <cargando v-if="cargandoComparativos" />

                <div class="px-3 m-3">
                    <div class="row cr-pointer" :class="filtro_relacion==4?'text-general':''" @click="filtro_relacion = 4">
                        <div class="col text-center px-0">
                            <p class="p-2">Hoy</p>
                        </div>
                        <div class="pt-1 w-100" :class="filtro_relacion==4?'bg-general3':'bg-grr-red'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==1?'text-general':''" @click="filtro_relacion = 1">
                        <div class="col text-center px-0">
                            <p class="p-2">Última semana</p>
                        </div>
                        <div class="pt-1 w-100" :class="filtro_relacion==1?'bg-general3':'bg-grr-red'" />
                    </div>
                    <div class="row cr-pointer" :class="filtro_relacion==2?'text-general':''" @click="filtro_relacion = 2">
                        <div class="col text-center px-0">
                            <p class="p-2">Último mes</p>
                        </div>
                        <div class="pt-1 w-100" :class="filtro_relacion==2?'bg-general3':'bg-grr-red'" />
                    </div>
                </div>
                <!-- Filtro -->
                <!-- Relaciones x filtro -->
                <div v-for="(rel, idx) in relaciones" :key="idx" class="mx-2 my-1 px-0" :class="idx>1 && rel.ids.length > 0 ? 'cr-pointer' : ''" @click="modalRelacion(idx,rel.ids,rel.nombre)">
                    <div
                    class="br-12 p-2 text-center px-4 bg-light-f5 text-general"
                    :class="{'border':( rel.porcentaje == 0 )}"
                    style="width:150px;height:60px;"
                    >
                        {{ rel.nombre }}
                    </div>
                    <div
                    class="row mx-0 my-1 px-2 justify-center"
                    :class="{ 'text-general':(rel.porcentaje>0), 'text-danger':(rel.porcentaje<0), '':(rel.porcentaje==0) }"
                    >
                        <i :class="{ 'icon-up':(rel.porcentaje>0), 'icon-down':(rel.porcentaje<0), '':(rel.porcentaje==0) }" />
                        <span>{{ rel.porcentaje>0?'+':'' }}{{ rel.porcentaje }}%</span>
                    </div>
                    <div class="row mx-0 my-1 f-22 px-2 text-muted justify-center">
                        {{ idx == 1 || idx == 2? separadorNumero(rel.valor) : agregarSeparadoresNumero(rel.valor) }}
                    </div>
                </div>
                <!-- Relaciones x filtro -->
            </div>
            <!-- Creicimiento en los ultimos xx -->
            <div class="row mx-5 f-18 my-4 f-600 text-general">
                {{$config.vendedor}}s activos en los últimos {{ chart_Crecimiento.dataset.source.length }} meses
            </div>
            <div class="position-relative">
                <cargando v-if="cargandoCrecimiento" />
                <chartBarras :datos="crecimientoClientes" :chart="chart_Crecimiento" />
            </div>
            <!-- Graficas -->
            <graficas ref="grafica" :tipo-fecha="tipo_fecha" />
            <!-- Graficas -->
            <!-- Creicimiento en los ultimos xx -->

            <div class="row mx-0 px-5 justify-content-start align-items-center my-2">
                <div class="col-auto d-middle text-general f-17">
                    Rango de análisis
                </div>
                <div class="col-auto" style="width:174px;">
                    <el-select v-model="rango" placeholder="Seleccionar" size="medium">
                        <el-option
                        v-for="item in rangos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0 mx-2" style="width:248px;">
                    <div class="date-picker-edit">
                        <el-date-picker
                        v-model="fecha"
                        class="w-100"
                        size="medium"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        :disabled="disable_fecha"
                        popper-class="date-picker-edit-popper"
                        type="daterange"
                        unlink-panels
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        />
                    </div>
                </div>
                <div class="col-auto">
                    <div class="cr-pointer text-white p-1 px-3 f-18 br-12 bg-general" @click="informes">
                        Mostrar informe
                    </div>
                </div>
                <cargando v-if="cargandoMetricas" mini />
            </div>

            <div class="row mx-0 justify-content-xl-around my-5">
                <div class="col-auto px-0 mx-1 d-middle">
                    <div class="bg-light-f5 shadow px-3 py-2 br-12 text-general mx-2 mr-3">
                        Cantidad de <br /> pedidos
                    </div>
                    <span class="f-25 text-general2">
                        {{ agregarSeparadoresNumero(comparativas.cantidad) }}
                    </span>
                </div>
                <div class="col-auto px-0 mx-1 d-middle">
                    <div class="bg-light-f5 shadow px-3 py-3 br-12 text-general mx-2 mr-3">
                        Ticket promedio
                    </div>
                    <span class="f-25 text-general2">
                        {{ separadorNumero(comparativas.promedio) }}
                    </span>
                </div>
                <div class="col-auto px-0 mx-1 d-middle">
                    <div class="bg-light-f5 shadow px-3 py-3 br-12 text-general mx-2 mr-3">
                        Total {{$config.vendedor}}s
                    </div>
                    <span class="f-25 text-general2">
                        {{ agregarSeparadoresNumero(comparativas.leecheros) }}
                    </span>
                </div>
                <div class="col-auto px-0 mx-1 d-middle">
                    <div class="bg-light-f5 shadow px-3 py-3 br-12 text-general mx-2 mr-3">
                        Valor total
                    </div>
                    <span class="f-25 text-general2">
                        {{ separadorNumero(comparativas.total) }}
                    </span>
                </div>
            </div>

            <div class="row mx-0 mt-5 mb-2">
                <div class="col-2 px-5 text-general f-18 f-600">
                    Ventas Promedio hora/semana
                </div>
                <div class="col-10">
                    <div class="row mx-5 justify-tipo-s1rt border-bottom">
                        <div
                        v-for="(data, i) in tipos"
                        :key="i"
                        class="col-auto cr-pointer p-2 f-14"
                        :class="tipo == data.id ? 'border-b-red text-general-red' : 'text-muted2' "
                        @click=" tipo = data.id"
                        >
                            {{ data.mes }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Chart Promedio -->
            <chartBarras :datos="datos_promedio" :chart="chart_promedio" />
            <!-- Chart Promedio -->
            <div class="row f-18 f-600 px-5 mx-0 mt-5 text-general align-items-center">
                Productos que han generado mayores ingresos
                <div v-if="id_cedis != 0" class="bg-light-f5 f-500 br-20 text-general2 px-2 ml-auto">
                    <i class="icon-package-variant text-general2 f-20" />
                    Stock actual
                </div>
            </div>
            <div class="row mx-0 px-5 py-3" :class="!productos_card_total.length ? 'justify-center' : ''">
                <template v-if="productos_card_total.length">
                    <div v-for="(producto,d) in productos_card_total" :key="d" class="col-auto position-relative">
                        <el-tooltip v-if="producto.estado == 0" placement="bottom" content="Inactivo" effect="light">
                            <div class="position-absolute z-1 rounded-circle bg-dark d-middle-center" style="right:20px;width:40px;height:40px;">
                                <i class="icon-eye-off f-25 text-white" />
                            </div>
                        </el-tooltip>
                        <div v-if="id_cedis != 0" class="position-absolute z-1 bg-light-f5 f-15 f-500 br-20 text-general2 ml-auto px-2 py-1" style="right:31px;top:-16px;">
                            <i class="icon-package-variant text-general2 f-15" />{{ producto.stock_actual }}
                        </div>
                        <el-tooltip placement="bottom" :content="`${producto.sku} - ${producto.nombre} - ${producto.presentacion}`" effect="light">
                            <div class="border br-12 d-middle-center" style="width:115px;height:115px;object-fit:cover;">
                                <img class="w-50" :src="producto.foto_firmada" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="mb-0 text-general2 f-15 text-center">
                            {{ separadorNumero(producto.total) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(producto.porcentaje_total,1) }}%
                        </p>
                    </div>
                </template>
                <!-- Sin datos -->
                <div v-else class="col">
                    <div class="row justify-center">
                        <div class="borde text-center p-2 br-12 sin_datos">
                            <img src="/img/no-imagen/mercado.svg" alt="" />
                        </div>
                    </div>
                    <div class="row justify-center f-15 text-general">
                        Aun no se han registrado ventas
                    </div>
                </div>
                <!-- Sin datos -->
                <div v-if="productos_ver_mas_total.total > 0" class="col-auto ml-auto">
                    <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirProductos(1)">
                        <p class="mb-0 text-white">
                            Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                        </p>
                    </div>
                    <p class="mb-0 text-general2 f-15 text-center">
                        {{ separadorNumero(productos_ver_mas_total.total) }}
                    </p>
                    <p class="mb-0 text-general f-600 f-18 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_total.porcentaje,1) }}%
                    </p>
                </div>
            </div>
            <div class="row f-18 f-600 px-5 mx-0 mt-5 text-general">
                Productos presentes en mayor número de pedidos
                <div v-if="id_cedis != 0" class="bg-light-f5 f-500 br-20 text-general2 px-2 ml-auto">
                    <i class="icon-package-variant text-general2 f-20" />
                    Stock actual
                </div>
            </div>
            <div class="row mx-0 px-5 py-3 mb-5" :class="!productos_card_cantidad.length ? 'justify-center' : ''">
                <template v-if="productos_card_cantidad.length">
                    <div v-for="(producto,d) in productos_card_cantidad" :key="d" class="col-auto position-relative">
                        <el-tooltip v-if="producto.estado == 0" placement="bottom" content="Inactivo" effect="light">
                            <div class="position-absolute z-1 rounded-circle bg-dark d-middle-center" style="right:20px;width:40px;height:40px;">
                                <i class="icon-eye-off f-25 text-white" />
                            </div>
                        </el-tooltip>
                        <div v-if="id_cedis != 0" class="position-absolute z-1 bg-light-f5 f-15 f-500 br-20 text-general2 ml-auto px-2 py-1" style="right:31px;top:-16px;">
                            <i class="icon-package-variant text-general2 f-15" />{{ producto.stock_actual }}
                        </div>
                        <el-tooltip placement="bottom" :content="`${producto.sku} - ${producto.nombre} - ${producto.presentacion}`" effect="light">
                            <div class="border br-12 d-middle-center" style="width:115px;height:115px;object-fit:cover;">
                                <img class="w-50" :src="producto.foto_firmada" alt="" />
                            </div>
                        </el-tooltip>
                        <p class="mb-0 text-general2 f-15 text-center">
                            {{ agregarSeparadoresNumero(producto.cantidad) }}
                        </p>
                        <p class="mb-0 text-general f-600 f-18 text-center">
                            {{ agregarSeparadoresNumero(producto.porcentaje_cantidad,1) }}%
                        </p>
                    </div>
                </template>
                <!-- Sin datos -->
                <div v-else class="col">
                    <div class="row justify-center">
                        <div class="borde text-center p-2 br-12 sin_datos">
                            <img src="/img/no-imagen/mercado.svg" alt="" />
                        </div>
                    </div>
                    <div class="row justify-center f-15 text-general">
                        Aun no se han registrado ventas
                    </div>
                </div>
                <!-- Sin datos -->
                <div v-if="productos_ver_mas_cantidad.cantidad > 0" class="col-auto ml-auto">
                    <div class="br-12 bg-general3 d-middle-center cr-pointer" style="width:115px;height:115px;" @click="AbrirProductos(2)">
                        <p class="mb-0 text-white">
                            Ver más <br /> <i class="icon-angle-right text-white ml-3" />
                        </p>
                    </div>
                    <p class="mb-0 text-general2 f-15 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.cantidad) }}
                    </p>
                    <p class="mb-0 text-general f-600 f-18 text-center">
                        {{ agregarSeparadoresNumero(productos_ver_mas_cantidad.porcentaje,1) }}%
                    </p>
                </div>
            </div>
            <!-- Compras por genero -->
            <div class="row mx-0 px-5 my-4 text-general f-600 f-18">
                Pedidos y entregas directas
            </div>
            <div class="row mx-0 my-2 px-5">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template>
                            <el-tooltip placement="top" :content="`${directos[0].nombre}: ${agregarSeparadoresNumero(directos[0].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${directos[0].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${directos[1].nombre}: ${agregarSeparadoresNumero(directos[1].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-muted br-tr-5 br-br-5" :style="`width:${directos[1].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${directos[2].nombre}: ${agregarSeparadoresNumero(directos[2].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-gris" :style="`width:${directos[2].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${directos[3].nombre}: ${agregarSeparadoresNumero(directos[3].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-light-f5 br-tr-5 br-br-5" :style="`width:${directos[3].porcentaje}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-content-center mt-4">
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#000000" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[0].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[0].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[0].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#6D6B6B" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[1].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[1].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[1].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#DBDBDB" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[2].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[2].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[2].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-flex" style="width:273px;height:95px;">
                        <div class="col-auto pt-4 px-0">
                            <i class="icon-brightness f-18" style="color:#f5f5f5" />
                        </div>
                        <div class="px-2 col pt-4">
                            <div class="row mx-0 f-14 text-general f-600">
                                <div class="col px-0">
                                    {{ directos[3].nombre }}
                                </div>
                                <div class="col-auto px-0">
                                    {{ agregarSeparadoresNumero(directos[3].porcentaje,2) }}%
                                </div>
                            </div>
                            <div class="row mx-0 my-1">
                                <div class="col text-general2 f-14 px-2">
                                    {{ separadorNumero(directos[3].total) }}
                                </div>
                                <div class="col text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(directos[3].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Compras por genero -->
            <div class="row mx-0 px-5 my-4 text-general f-600 f-18">
                Compradores por género
            </div>
            <div class="row mx-0 my-2 px-5">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template v-if="datos == true">
                            <el-tooltip placement="top" :content="`${generos[1].nombre}: ${agregarSeparadoresNumero(generos[1].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${generos[1].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${generos[0].nombre}: ${agregarSeparadoresNumero(generos[0].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general2" :style="`width:${generos[0].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${generos[2].nombre}: ${agregarSeparadoresNumero(generos[2].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-gris br-tr-5 br-br-5" :style="`width:${generos[2].porcentaje}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-content-center">
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Mujer {{ agregarSeparadoresNumero(generos[1].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[1].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/person (2).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Hombre {{ agregarSeparadoresNumero(generos[0].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[0].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/astronaut (1).svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Otro {{ agregarSeparadoresNumero(generos[2].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(generos[2].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(generos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 px-5 my-4 text-general f-600 f-18">
                Compradores por edad
            </div>
            <div class="row mx-0 px-5">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <el-tooltip v-for="(data, i) in compradores_edad" :key="i" placement="top" :content="`${data.edad}: ${separadorNumero(data.total)}`" effect="light">
                            <div v-if="data.estado != 11" class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5" :class="backgroundEdad(data.estado)" :style="`width:${data.porcentaje}%;`" />
                            <div v-else class="h-100 br-tl-5 br-bl-5 br-tr-5 br-br-5 bg-dark" :style="`width:${data.porcentaje}%;`" />
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="row mx-0 px-5 mt-3 justify-center">
                <div v-for="(data, i) in compradores_edad" :key="i" class="col-2 px-2 my-2 text-muted2" style="min-height:95px;">
                    <div class="w-100 h-100 border br-12 px-3">
                        <p class="f-17 tres-puntos py-1">
                            <span class="f-600" :class="estiloEdad(data.estado)"> {{ data.edad }}:   {{ data.porcentaje }}% </span>
                        </p>
                        <p class="f-14 tres-puntos py-1">
                            {{ separadorNumero(data.total) }}
                        </p>
                        <p class="f-14 tres-puntos py-1">
                            {{ agregarSeparadoresNumero(data.cantidad) }} Pedidos
                        </p>
                    </div>
                </div>
            </div>
            <!-- Compras por genero -->
            <div class="row mx-0 px-5 my-4 text-general f-600 f-18">
                Compradores por dispositivo
            </div>
            <div class="row mx-0 my-2 px-5">
                <div class="col">
                    <div class="d-flex br-5" style="height:30px;background-color:#EBEBEB;">
                        <template v-if="datos == true">
                            <el-tooltip placement="top" :content="`${dispositivos[1].nombre}: ${agregarSeparadoresNumero(dispositivos[1].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general3 br-tl-5 br-bl-5" :style="`width:${dispositivos[1].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${dispositivos[2].nombre}: ${agregarSeparadoresNumero(dispositivos[2].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-general2" :style="`width:${dispositivos[2].porcentaje}%;`" />
                            </el-tooltip>
                            <el-tooltip placement="top" :content="`${dispositivos[0].nombre}: ${agregarSeparadoresNumero(dispositivos[0].porcentaje)}%`" effect="light">
                                <div class="h-100 bg-gris br-tr-5 br-br-5" :style="`width:${dispositivos[0].porcentaje}%;`" />
                            </el-tooltip>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-content-center mt-4">
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/android.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Android {{ agregarSeparadoresNumero(dispositivos[1].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[1].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[1].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/apple.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                iOS {{ agregarSeparadoresNumero(dispositivos[2].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[2].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[2].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="border br-12 d-middle-center" style="width:273px;height:95px;">
                        <img src="/img/icons/world-wide-web.svg" style="width:50px;height:65px;object-fit:cover;" alt="" />
                        <div class="px-2 ">
                            <p class="mb-0 f-18 text-general f-600">
                                Web {{ agregarSeparadoresNumero(dispositivos[0].porcentaje,2) }}%
                            </p>
                            <div class="row mx-0">
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ separadorNumero(dispositivos[0].total) }}
                                </div>
                                <div class="col-auto text-general2 f-14 px-2">
                                    {{ agregarSeparadoresNumero(dispositivos[0].cantidad) }} Pedidos
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-6 mx-0 pt-5 px-5 text-general f-600 f-18">
                    Comportamiento de compra de los {{$config.vendedor}}s
                </div>
                <div class="col-6 ml-auto mx-0 pt-5 px-5" style="width:170px;">
                    <p class="mb-0 text-general text-right f-18">
                        {{ agregarSeparadoresNumero(total_clientes_paginado) }}/{{ agregarSeparadoresNumero(total_clientes) }} Leecheros
                    </p>
                </div>
            </div>
            <div class="position-relative">
                <cargando v-if="cargandoClientes" />
                <!-- Tabla -->
                <div class="row mx-3 mt-5 justify-center">
                    <div class="col-3 text-center">
                        <div class="d-middle-center text-general2 justify-center">
                            {{$config.vendedor}}s
                        </div>
                    </div>
                    <div class="col text-center">
                        <div class="d-middle-center text-general2 mx-3 cr-pointer justify-center" @click="ordenar(1)">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(1)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(1)" style="" />
                                </div>
                            </div>
                            <!-- <i class="f-20 icon-swap-vertical-bold" :class="iconFiltro(1)" /> -->
                            Pedidos totales
                        </div>
                    </div>
                    <div class="col text-center">
                        <div class="d-middle-center text-general2 cr-pointer justify-center" @click="ordenar(2)">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(2)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(2)" style="" />
                                </div>
                            </div>
                            Compras totales
                        </div>
                    </div>
                    <div class="col text-center">
                        <div class="d-middle-center text-general2 mx-3 cr-pointer justify-center" @click="ordenar(3)">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(3)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(3)" style="" />
                                </div>
                            </div>
                            Pedidos en la fecha
                        </div>
                    </div>
                    <div class="col text-center">
                        <div class="d-middle-center text-general2 cr-pointer justify-center" @click="ordenar(4)">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(4)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(4)" style="" />
                                </div>
                            </div>
                            Compras en la fecha
                        </div>
                    </div>
                    <div class="col text-center">
                        <div class="d-middle-center cr-pointer justify-center" @click="ordenar(5)">
                            <div class="position-relative mx-1">
                                <i class="icon-swap-vertical-bold f-18 position-absolute mx-1 text-gris" style="left:-31px;top:-14px;" />
                                <div class="position-relative" :class="containerClass(5)">
                                    <i class="f-18 position-absolute" :class="iconFiltro(5)" style="" />
                                </div>
                            </div>
                            Ticket Promedio
                        </div>
                    </div>
                </div>
                <div v-for="(cliente,c) in clientes_paginado" :key="c" class="row mx-4 border my-2 p-1 br-12 align-items-center cr-pointer" style="min-height:69px;" @click="AbrirInfoClientes(cliente)">
                    <div class="col-3 px-1">
                        <div class="d-flex justify-left">
                            <img :src="cliente.avatar" class="border" style="width:54px;height:54px;border-radius:50%;object-fit:cover;" />
                            <div class="mx-3 py-2">
                                <p class="mb-0 f-18 f-600 text-general">
                                    {{ cliente.nombre }}
                                </p>
                                <p class="mb-0 text-general2 f-14">
                                    {{$config.vendedor}} desde {{ cliente.antiguedad }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col px-1 text-center">
                        <p class="mb-0 f-22 f-600 text-general2">
                            {{ agregarSeparadoresNumero(cliente.cantidad_total) }}
                        </p>
                    </div>
                    <div class="col px-1 text-center">
                        <el-tooltip class="item" effect="light" placement="bottom">
                            <div slot="content" class="text-general2" style="width:80px;">
                                <p>Promedio total</p>
                                {{ separadorNumero(cliente.promedio_total) }}
                            </div>
                            <p class="mb-0 f-22 f-600 text-general2">
                                {{ separadorNumero(cliente.total_total) }}
                            </p>
                        </el-tooltip>
                    </div>
                    <div class="col px-1 text-center">
                        <p class="mb-0 f-22 f-600 text-general2">
                            {{ agregarSeparadoresNumero(cliente.cantidad_fecha) }}
                        </p>
                    </div>
                    <div class="col px-1 text-center">
                        <p class="mb-0 f-22 f-600 text-general2">
                            {{ separadorNumero(cliente.total_fecha) }}
                        </p>
                    </div>
                    <div class="col px-1 text-center">
                        <p class="mb-0 f-22 f-600 text-general2">
                            {{ separadorNumero(cliente.promedio_fecha) }}
                        </p>
                    </div>
                </div>
                <!-- Tabla -->
                <div class="row mx-0 justify-content-end px-5 my-3">
                    <div class="col-auto mr-5" style="width:170px;" />
                    <div class="col-3 d-middle-center">
                        <div v-if="total_clientes > total_clientes_paginado" class="cr-pointer br-20 text-white p-1 px-3 bg-general3 mx-1" @click="agregar_mas_clientes">
                            Ver más
                        </div>
                        <div v-if="total_clientes > total_clientes_paginado" class="cr-pointer br-20 text-white p-1 px-3 bg-general3 mx-1" @click="verTodos">
                            Ver todos
                        </div>
                    </div>
                    <div class="col-3 d-middle-center" />
                    <div class="col-auto ml-3">
                        <p class="mb-0 text-general f-18">
                            {{ agregarSeparadoresNumero(total_clientes_paginado) }}/{{ agregarSeparadoresNumero(total_clientes) }} {{$config.vendedor}}s
                        </p>
                    </div>
                </div>
            </div>
        </template>
        <!-- Modales -->
        <modal-info-clientes ref="infoClientes" />
        <modal-productos ref="productos" />
        <modalDetalleRelacion ref="modalDetalleRelacion" />
        <!-- Modales -->
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import echarts from 'echarts'
import moment from 'moment'
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')
import MetricasAdmin from "~/services/metricas_admin"
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];
const fecha_timer = moment()


export default {
    components: {
        modalInfoClientes: () => import ('./partials/modalInfoClientes'),
        modalProductos: () => import ('./partials/modalProductos'),
        graficas: () => import ('./graficas'),
        modalDetalleRelacion: () => import ('./partials/modalDetalleRelacion')
    },
    data(){
        return{
            cargandoComparativos: true,
            cargandoCrecimiento: true,
            cargandoClientes: true,
            cargandoMetricas: true,
            compradores_edad: [
                { edad: '-20', estado: 1, cantidad: 6, porcentaje: 5, total : 0},
                { edad: '20-25', estado: 2, cantidad: 8 , porcentaje: 5, total : 0},
                { edad: '25-30', estado: 3, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '30-35', estado: 4, cantidad: 3 , porcentaje: 10, total : 0},
                { edad: '35-40', estado: 5, cantidad: 4 , porcentaje: 10, total : 0},
                { edad: '40-45', estado: 6, cantidad: 14 , porcentaje: 10, total : 0},
                { edad: '45-50', estado: 7, cantidad: 25 , porcentaje: 10, total : 0},
                { edad: '50-55', estado: 8, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '55-60', estado: 9, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: '+60', estado: 10, cantidad: 12 , porcentaje: 10, total : 0},
                { edad: 'Sin Determinar', estado: 11, cantidad: 12 , porcentaje: 10, total : 0},
            ],
            loading: false,
            tipo_fecha: 1,
            filtro_relacion: 1,
            tipo_relacion: 1,
            relaciones: [
                { nombre: 'Cantidad de pedidos', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Total ventas', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Ticket promedio', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Total Leecheros', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Leecheros recuperados', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Leecheros recurrentes', porcentaje: 0, valor: 0, ids: [] },
                { nombre: 'Leecheros perdidos', porcentaje: 0, valor: 0, ids: [] },
            ],
            datos: true,
            datos_promedio: [
                { nombre: 'Hora de mayor venta promedio', valor: 0, fecha: 'Ene. 2020', color: 'bg-gr-general', formato:1 },
                { nombre: 'Hora de menor venta promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-pink', formato:1 },
                { nombre: 'Hora de mayor # pedidos promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-red', formato:2 },
                { nombre: 'Hora de menor # pedidos promedio', valor: 0, fecha: 'Oct. 2019', color: 'bg-gr-red', formato:2 }
            ],
            fecha: [moment().subtract(1, 'week').toDate(),new Date()],
            disable_fecha: true,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Ultima semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Ultimo mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Ultimo año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 3,
            tipo: 1,
            tipos: [
                { mes: 'Cada Hora', id: 1 },
                { mes: 'Cada día', id: 2 }
            ],
            chart_promedio: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['alias', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral
                        },
                    }
                ]
            },
            dias: [],
            horas: [],
            min_dias_cantidad: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_dias_cantidad: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_dias_total: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_dias_total: {
                nombre: '',
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_horas_cantidad: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_horas_cantidad: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            max_horas_total: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            min_horas_total: {
                cantidad: 0,
                total: 0,
                alias: ''
            },
            productos_card_total: [],
            productos_ver_mas_total: {
                total: 0,
                porcentaje: 0
            },
            productos_card_cantidad: [],
            productos_ver_mas_cantidad: {
                total: 0,
                porcentaje: 0
            },
            clicksPedidos: 1,
            crecimientoClientes: [
                { nombre: 'Leecheros totales', valor: 0, color: 'bg-gr-general', formato:2 },
                { nombre: 'Mes con mayor crecimiento', valor: 0, fecha: '' ,color: 'bg-gr-pink', formato:2 }
            ],
            chart_Crecimiento : {
                backgroundColor: '#fff',
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        label: {
                            show: true,
                            backgroundColor: '#fff',
                            color: '#556677',
                            borderColor: 'rgba(0,0,0,0)',
                            shadowColor: 'rgba(0,0,0,0)',
                            shadowOffsetY: 0
                        },
                        lineStyle: {
                            width: 1
                        }
                    },
                    backgroundColor: '#fff',
                    textStyle: {
                        color: '#5c6c7c'
                    },
                    padding: [5, 5],
                    extraCssText: 'box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)'
                },
                xAxis: [
                    {
                        type: 'category',
                        // data: ['Ener', 'Feb', 'Mar', 'abril', 'may','jun'],
                        axisLine: {
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#556677'
                            },
                            fontSize: 12,
                            margin: 15
                        },
                        axisPointer: {
                            label: {
                                padding: [0, 0, 10, 0],
                                margin: 15,
                                fontSize: 12,
                                backgroundColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#fff'
                                    }, {

                                        offset: 0.86,
                                        color: '#fff'
                                    }, {
                                        offset: 0.86,
                                        color: colorGeneral
                                    }, {
                                        offset: 1,
                                        color: colorGeneral
                                    }],
                                    global: false
                                }
                            }
                        },
                        boundaryGap: false
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            }
                        },
                        splitLine: {
                            show: true
                        }
                    }, {
                        type: 'value',
                        position: 'right',
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#556677'
                            },
                            formatter: '{value}'
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#DCE2E8'
                            }
                        },
                        splitLine: {
                            show: false
                        }
                    }
                ],
                dataset:{
                    dimensions:['fecha','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Crecimiento',
                        type: 'line',
                        // data: [10, 10, 14, 12, 15, 3, 7],
                        symbol: 'circle',
                        symbolSize: 10,
                        smooth: true,
                        yAxisIndex: 0,
                        showSymbol: false,
                        lineStyle: {
                            width: 5,
                            color: colorGeneral,
                            shadowColor: 'rgba(158,135,255, 0.3)',
                            shadowBlur: 10,
                            shadowOffsetY: 20
                        },
                        itemStyle: {
                            normal: {
                                color:  colorGeneral,
                                borderColor: colorGeneral
                            }
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#E5E5E5'
                                },
                                {
                                    offset: 1,
                                    color: '#F0F0F0'
                                }
                                ], false)
                            }
                        },
                    }
                ]
            },
            chart_Crecimiento2: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Activos']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    // {
                    //     type: 'category',
                    //     boundaryGap: true,
                    // }
                ],
                yAxis: [
                    {
                        type: 'value',
                        // scale: true,
                        // name: 'Valor',
                    },
                    // {
                    //     type: 'value',
                    //     scale: true,
                    //     // name: 'Pedidos',
                    // }
                ],
                dataset:{
                    dimensions:['fecha', 'cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Activos',
                        type: 'bar',
                        // xAxisIndex: 1,
                        // yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    },
                    // {
                    //     name: 'Crecimiento',
                    //     type: 'line',
                    //     symbol:'circle',
                    //     symbolSize: 10,
                    //     itemStyle: {
                    //         color: colorGeneral
                    //     },
                    // }
                ]
            },
            clientes: [],
            clientes_paginado: [],
            total_clientes: 0,
            total_clientes_paginado: 0,
            total_por_pagina: 10,
            semana:{},
            ultimo_mes:{},
            ultimo_trimestre:{},
            hoy:{},
            generos: [
                {
                    nombre: "Femenino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Masculino",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Otro",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            dispositivos: [
                {
                    nombre: "Android",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "iOS",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Web",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            directos: [
                {
                    nombre: "Leecheros con la misma dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Leecheros con diferente dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Clientes con la misma dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                },
                {
                    nombre: "Clientes con diferente dirección",
                    valor: 0,
                    cantidad: 0,
                    total: 0,
                    porcentaje: 0
                }
            ],
            cedis_valores: {
                productos: null,
                unidades: null,
                valores: null
            },
            interval: null,
            fechaInforme: '',
            metricas_created: null,
            metricas_entregas: null,
            clientes_informe_created: null,
            clientes_informe_entregas: null,
            crecimiento_entregas: null,
            crecimiento_created: null,
            semana_created: null,
            ultimo_mes_created: null,
            ultimo_trimestre_created: null,
            hoy_created: null,
            semana_entregas: null,
            ultimo_mes_entregas: null,
            ultimo_trimestre_entregas: null,
            hoy_entregas: null,
            primeraVez: true,
            comparativas:{
                cantidad: 0,
                promedio: 0,
                leecheros: 0,
                total: 0,
            },
            order: true,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            info_cedis:'cedis/info_cedis',
        }),
        caso(){
            let caso = 0
            switch (this.$usuario.rol_nombre){
            case 'admin-vip':
                if (this.$route.params.id_tienda){
                    caso = 2
                } else {
                    caso = 1
                }
                break;
            case 'tendero-vip':
                caso = 3
                break;
            case 'admin':
                if (this.$route.params.id_tienda){
                    caso = 5
                } else {
                    caso = 4
                }
                break;

            default:
                break;
            }
            return caso
        },
        cedi(){
            return this.$route.params.id_cedis ?? this.id_cedis
        },
        cargandoTerminado(){
            return  !this.cargandoComparativos &&
                    !this.cargandoCrecimiento &&
                    !this.cargandoClientes &&
                    !this.cargandoMetricas &&
                    (this.$refs.grafica && !this.$refs.grafica.loading1) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading2) &&
                    (this.$refs.grafica && !this.$refs.grafica.loading3)
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        tipo(){
            this.montarSemanaHoras()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
            this.$refs.grafica.cargando()
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            if(this.primeraVez) return
            this.cargando()
            this.montar()
            this.$refs.grafica.cargando()
        },
        filtro_relacion(){
            this.calcularComparativos()
        },
        tipo_fecha(){
            this.calcularMetricasInformes()
            this.calcularInforme()
            this.calcularCrecimiento()
            this.calcularComparativos()
        },
        cargandoTerminado(val){
            this.$store.commit('cedis/setCargandoTerminado', val)
        }
    },
    mounted(){
        this.$store.commit('cedis/setCargandoTerminado', false)

		this.crecimientoClientes[0].nombre = `${this.$config.vendedor} totales`

        this.directos[0].nombre = `${this.$config.vendedor}s con la misma dirección`
        this.directos[1].nombre = `${this.$config.vendedor}s con diferente dirección`


        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoComparativos = true
                this.cargandoCrecimiento = true
            }
            this.cargandoClientes = true
            this.cargandoMetricas = true
        },
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.comparativos()
                        await this.crecimiento()
                        await this.$refs.grafica.montar()
                        await this.metricas_informe()
                        await this.informe()
                    }, 1000);
                }else{
                    await this.comparativos()
                    await this.crecimiento()
                    await this.$refs.grafica.montar()
                    await this.metricas_informe()
                    await this.informe()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        estiloEdad(estado){
            switch(estado){
            case 1:
                return 'text-orange-clear_2'
            case 2:
                return 'text-green'
            case 3:
                return 'text-purple'
            case 4:
                return 'text-orange-clear'
            case 5:
                return 'text-dark-blue'
            case 6:
                return 'text-blue-light'
            case 7:
                return 'text-pink-light'
            case 8:
                return 'text-dark-green'
            case 9:
                return 'text-blue'
            case 10:
                return 'text-dark-pink'
            case 11:
                return 'text-dark'
            }
        },
        backgroundEdad(estado){
            switch(estado){
            case 1:
                return 'bg-orange-clear_2'
            case 2:
                return 'bg-green'
            case 3:
                return 'bg-purple'
            case 4:
                return 'bg-orange-clear'
            case 5:
                return 'bg-dark-blue'
            case 6:
                return 'bg-blue-light'
            case 7:
                return 'bg-pink-light'
            case 8:
                return 'bg-dark-green'
            case 9:
                return 'bg-blue'
            case 10:
                return 'bg-dark-pink'
            case 11:
                return 'bg-dark'
            /* case 10:
                return 'text-green' */
            }
        },
        modalRelacion(idx,ids,titulo){
            if (idx>1 && ids.length > 0){
                let filtro = ''
                switch (this.filtro_relacion){
                case 1:
                    filtro = "Durante última semana"
                    break;
                case 2:
                    filtro = "Durante último mes"
                    break;
                case 3:
                    filtro = "Durante último trimestre"
                    break;
                case 4:
                    filtro = "Durante último día"
                    break;
                default:
                    break;
                }
                this.$refs.modalDetalleRelacion.toggle(ids,titulo,filtro)
            }
        },
        AbrirProductos(tipo){
            let params = {
                fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                tipo,
                id_cedis: this.cedi,
                id_moneda: this.id_moneda,
                tipo_fecha: this.tipo_fecha,
            }
            this.$refs.productos.toggle(params)
        },
        async metricas_informe(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedi
                }

                const { data } = await MetricasAdmin.metricas_informe(params)

                this.metricas_created = data.data.pedidos_created
                this.metricas_entregas = data.data.pedidos_entregas

                this.calcularMetricasInformes()
                // this.notificacion('Mensaje','Informe Actualizado correctamente','success')


            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoMetricas = false
            }
        },
        calcularMetricasInformes(){
            try {

                let datos = this.tipo_fecha == 1 ? this.metricas_entregas : this.metricas_created

                this.dias = datos.dias
                this.horas = datos.horas
                this.max_dias_cantidad = datos.max_dias_cantidad
                this.min_dias_cantidad = datos.min_dias_cantidad
                this.max_dias_total = datos.max_dias_total
                this.min_dias_total = datos.min_dias_total
                this.max_horas_cantidad = datos.max_horas_cantidad
                this.min_horas_cantidad = datos.min_horas_cantidad
                this.max_horas_total = datos.max_horas_total
                this.min_horas_total = datos.min_horas_total

                this.montarSemanaHoras()

                datos.productos_card_total.map(p=>{
                    p.stock_actual = this.agregarSeparadoresNumero(p.stock_actual)
                })
                this.productos_card_total = datos.productos_card_total
                this.productos_ver_mas_total = datos.productos_ver_mas_total

                datos.productos_card_cantidad.map(p=>{
                    p.stock_actual = this.agregarSeparadoresNumero(p.stock_actual)
                })
                this.productos_card_cantidad = datos.productos_card_cantidad
                this.productos_ver_mas_cantidad = datos.productos_ver_mas_cantidad

                //generos
                this.directos = datos.directos
                this.generos = datos.generos
                this.dispositivos = datos.dispositivos
                //edades
                this.compradores_edad = datos.edades
                this.comparativas = datos.comparativas
            } catch (error){
                console.log(error);
            }
        },
        montarSemanaHoras(){
            try {
                if (this.tipo == 1){
                    this.chart_promedio.dataset.source = this.horas
                    this.datos_promedio[0].nombre = 'Hora de mayor venta promedio'
                    this.datos_promedio[0].valor = this.max_horas_total ? this.max_horas_total.total : 0
                    this.datos_promedio[0].fecha = this.max_horas_total ? this.max_horas_total.alias : 'No registra'
                    this.datos_promedio[1].nombre = 'Hora de menor venta promedio'
                    this.datos_promedio[1].valor = this.min_horas_total ? this.min_horas_total.total : 0
                    this.datos_promedio[1].fecha = this.min_horas_total ? this.min_horas_total.alias : 'No registra'
                    this.datos_promedio[2].nombre = 'Hora de mayor # pedidos promedio'
                    this.datos_promedio[2].valor = this.max_horas_cantidad ? this.max_horas_cantidad.cantidad : 0
                    this.datos_promedio[2].fecha = this.max_horas_cantidad ? this.max_horas_cantidad.alias : 'No registra'
                    this.datos_promedio[3].nombre = 'Hora de menor # pedidos promedio'
                    this.datos_promedio[3].valor = this.min_horas_cantidad ? this.min_horas_cantidad.cantidad :  0
                    this.datos_promedio[3].fecha = this.min_horas_cantidad ? this.min_horas_cantidad.alias :  'No registra'
                } else {
                    this.chart_promedio.dataset.source = this.dias
                    this.datos_promedio[0].nombre = 'Día de mayor venta promedio'
                    this.datos_promedio[0].valor = this.max_dias_total ? this.max_dias_total.total : 0
                    this.datos_promedio[0].fecha = this.max_dias_total ? this.max_dias_total.alias : 'No registra'
                    this.datos_promedio[1].nombre = 'Día de menor venta promedio'
                    this.datos_promedio[1].valor = this.min_dias_total ? this.min_dias_total.total : 0
                    this.datos_promedio[1].fecha = this.min_dias_total ? this.min_dias_total.alias : 'No registra'
                    this.datos_promedio[2].nombre = 'Día de mayor # pedidos promedio'
                    this.datos_promedio[2].valor = this.max_dias_cantidad ? this.max_dias_cantidad.cantidad : 0
                    this.datos_promedio[2].fecha = this.max_dias_cantidad ? this.max_dias_cantidad.alias : 'No registra'
                    this.datos_promedio[3].nombre = 'Día de menor # pedidos promedio'
                    this.datos_promedio[3].valor = this.min_dias_cantidad ? this.min_dias_cantidad.cantidad : 0
                    this.datos_promedio[3].fecha = this.min_dias_cantidad ? this.min_dias_cantidad.alias : 'No registra'
                }
            } catch (error){
                console.log(error);
            }
        },
        iconFiltro(dato){
            if (this.clicksPedidos === dato){
                return this.order ? 'flecha-orden text-general abajo mover-abajo' : 'flecha-orden girar text-general arriba mover-arriba'
            } else {
                return 'text-gris'
            }
        },
        containerClass(dato){
            if (this.clicksPedidos === dato){
                return this.order ? ' text-general abajo' : 'girar text-general arriba'
            } else {
                return 'text-gris'
            }
        },
        AbrirInfoClientes(cliente){
            this.$refs.infoClientes.toggle(cliente,this.caso,this.id_tienda_clientes);
        },
        ordenar(tipo){
            this.clicksPedidos = tipo
            let mismoTipo = tipo == this.clicksPedidos
            if (mismoTipo){
                this.order = !this.order
            }else{
                this.order = orden == 'desc'
            }
            let ordenar = this.order ? 'desc' : 'asc'
            switch (tipo){
            case 1:
                this.clientes_paginado = _.orderBy(this.clientes,'cantidad_total',ordenar).slice(0,this.total_por_pagina)
                break;
            case 2:
                this.clientes_paginado = _.orderBy(this.clientes,'total_total',ordenar).slice(0,this.total_por_pagina)
                break;
            case 3:
                this.clientes_paginado = _.orderBy(this.clientes,'cantidad_fecha',ordenar).slice(0,this.total_por_pagina)
                break;
            case 4:
                this.clientes_paginado = _.orderBy(this.clientes,'total_fecha',ordenar).slice(0,this.total_por_pagina)
                break;
            case 5:
                this.clientes_paginado = _.orderBy(this.clientes,'promedio_fecha',ordenar).slice(0,this.total_por_pagina)
                break;

            default:
                break;
            }
        },
        agregar_mas_clientes(){
            if (this.total_clientes >= this.total_por_pagina){
                this.total_por_pagina += 20
                this.clientes_paginado = this.clientes.slice(0,this.total_por_pagina)
                this.total_clientes_paginado = this.clientes_paginado.length
            }
        },
        verTodos(){
            if (this.total_clientes >= this.total_por_pagina){
                this.total_por_pagina = this.total_clientes
                this.clientes_paginado = this.clientes
                this.total_clientes_paginado = this.clientes_paginado.length
            }
        },
        async crecimiento(){
            try {
                let params = {
                    caso: this.caso,
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedi
                }
                const { data } = await MetricasAdmin.crecimiento(params)
                this.$validar(data)

                this.crecimiento_created = data.data.pedidos_created
                this.crecimiento_entregas = data.data.pedidos_entregas

                this.calcularCrecimiento()

                this.cedis_valores = data.data.info_cedis

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoCrecimiento = false
            }
        },
        calcularCrecimiento(){
            try {

                let datos = this.tipo_fecha == 1 ? this.crecimiento_entregas : this.crecimiento_created

                this.chart_Crecimiento.dataset.source = datos.clientes
                this.crecimientoClientes[0].valor = datos.total_clientes
                this.crecimientoClientes[1].valor = datos.crecimiento_mayor ? datos.crecimiento_mayor.crecimiento : 0
                this.crecimientoClientes[1].fecha = datos.crecimiento_mayor ? datos.crecimiento_mayor.fecha : 'No registra'

            } catch (error){
                this.error_catch(error)
            }
        },
        async informe(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    caso: this.caso,
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedi
                }
                const { data } = await MetricasAdmin.informe(params)

                this.clientes_informe_created = data.data.pedidos_created
                this.clientes_informe_entregas = data.data.pedidos_entregas
                this.total_por_pagina = 10

                this.calcularInforme()
            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoClientes = false
            }
        },
        calcularInforme(){
            try {
                let datos = this.tipo_fecha == 1 ? this.clientes_informe_entregas : this.clientes_informe_created

                this.clientes = datos.clientes
                this.clientes_paginado = this.clientes.slice(0,this.total_por_pagina)
                this.total_clientes = datos.total_clientes
                this.total_clientes_paginado = this.clientes_paginado.length
            } catch (error){
                this.error_catch(error)
            }
        },
        async comparativos(){
            try {
                let params =  {
                    id_moneda: this.id_moneda,
                    id_cedis: this.cedi
                }



                const { data } = await MetricasAdmin.comparativos(params)
                this.$validar(data)

                this.semana_created = data.data.semana.pedidos_created
                this.ultimo_mes_created = data.data.ultimo_mes.pedidos_created
                this.hoy_created = data.data.hoy.pedidos_created
                this.semana_entregas = data.data.semana.pedidos_entregas
                this.ultimo_mes_entregas = data.data.ultimo_mes.pedidos_entregas
                this.hoy_entregas = data.data.hoy.pedidos_entregas

                this.calcularComparativos()

            } catch (error){
                this.error_catch(error)
            }finally{
                this.cargandoComparativos = false
            }
        },
        calcularComparativos(){
            try {

                this.semana = this.tipo_fecha == 1 ? this.semana_entregas : this.semana_created
                this.ultimo_mes = this.tipo_fecha == 1 ? this.ultimo_mes_entregas : this.ultimo_mes_created
                this.hoy = this.tipo_fecha == 1 ? this.hoy_entregas : this.hoy_created

                let tipo = {}
                switch (this.filtro_relacion){
                case 1:
                    tipo = this.semana
                    break;
                case 2:
                    tipo = this.ultimo_mes
                    break;
                case 4:
                    tipo = this.hoy
                    break;
                default:
                    break;
                }

                this.relaciones[0].porcentaje = tipo.pedidos.porcentaje
                this.relaciones[0].valor = tipo.pedidos.valor
                this.relaciones[1].porcentaje = tipo.ventas.porcentaje
                this.relaciones[1].valor = tipo.ventas.valor
                this.relaciones[2].porcentaje = tipo.promedio.porcentaje
                this.relaciones[2].valor = tipo.promedio.valor
                this.relaciones[3].porcentaje = tipo.tenderos.porcentaje
                this.relaciones[3].valor = tipo.tenderos.valor
                this.relaciones[3].ids = tipo.tenderos.id
                this.relaciones[4].porcentaje = tipo.nuevos.porcentaje
                this.relaciones[4].valor = tipo.nuevos.valor
                this.relaciones[4].ids = tipo.nuevos.id
                this.relaciones[5].porcentaje = tipo.recurrentes.porcentaje
                this.relaciones[5].valor = tipo.recurrentes.valor
                this.relaciones[5].ids = tipo.recurrentes.id
                this.relaciones[6].porcentaje = tipo.perdidos.porcentaje
                this.relaciones[6].valor = tipo.perdidos.valor
                this.relaciones[6].ids = tipo.perdidos.id
            } catch (error){
                console.log(error)
            }
        },
        async informes(){
            try {
                this.tipo = 1
                this.cargando(false)

                await this.metricas_informe()
                await this.informe()

            } catch (error){
                console.log(error)
            }
        }
    }
}
</script>
<style lang="scss" >
.text-blue{
    color: #0892DD;
}
.bg-blue{
    background-color: #0892DD;
}
.br-12{
    border-radius: 12px;
}
.border-bottom-filtro{
        border-bottom: 4px solid #FF9D32;
        border-radius: 1px;
    }
.bg-orange{
    background-color: #FF9539;
}
.text-orange{
    color: #FF9539;
}
.bg-orange-clear{
    background-color: #FFDF75;
}
.text-orange-clear_2{ color: #FFB87A;}
.text-orange-clear{ color: #FFDF75; }
.text-green{ color: #02D6BC;}
.text-dark-blue{ color: #284367;}
.text-blue-light{ color: #0892DD ;}
.text-pink-light{ color:#FFB1D0; }
.text-dark-green{ color: #03BB83;}
.text-blue{ color: #6BD1FF;}
.text-dark-pink{ color:#E33FC3;}

.bg-orange-clear_2{ background-color: #FFB87A;}
.bg-orange-clear{ background-color: #FFDF75; }
.bg-green{ background-color: #02D6BC;}
.bg-dark-blue{ background-color: #284367;}
.bg-blue-light{ background-color: #0892DD ;}
.bg-pink-light{ background-color:#FFB1D0; }
.bg-dark-green{ background-color: #03BB83;}
.bg-blue{ background-color: #6BD1FF;}
.bg-dark-pink{ background-color:#E33FC3;}


.bg-blue-clear{background-color: #03D6BC;}
.bg-blue-dark{ background-color: #284367;}
.bg-dark{ background-color: #333435;}
.text-dark{ color: #333435;}
.text-blue-clear{ color: #03D6BC; }
.bg-purple{
    background-color: #6D3CE1;
}
.text-purple{
    color: #6D3CE1;
}
.sin_datos{
    width:115px;
    height:115px;
    background-color:#f3f3f3;
}
.height-cedis{
    height: calc(100vh - 145px);
}
.height-otro{
    height: calc(100vh - 131px);
}
.active-switch{
    background-color: var(--color-general);
    color: #ffffff;
}
.switch{
    background-color: #ffffff;
    color:  var(--color-general);
}
.br-tl-12{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.br-tr-12{
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.br-12{
    border-radius: 12px;
}
</style>
